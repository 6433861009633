*
{
    margin: 0;
    padding: 0;
    /* height: -webkit-fill-available; */
}

@import url('https://fonts.googleapis.com/css?family=Amatic+SC');

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

body {
    overflow: hidden;
    background-color: #000000;
    /* min-height: 100vh;
    min-height: -webkit-fill-available; */
}

.overlay{
    opacity:1;
    background-color:rgb(0, 0, 0);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    pointer-events:none;
    transition: opacity 1s;
}

.overlay.fade{
    opacity:0;
}

h1 {
    position: absolute;
    margin: auto;
    bottom: -5vh;
    width: 30vh;
    min-width: 30vmax;
    text-align: center;
    font-family: 'Amatic SC';
    font-size: 2vmax;
    color: #ffffff;
    opacity: 0.75;
    animation: pulse 2.5s linear infinite;
}

.start{
    position: fixed;
    top: calc((100vh - 100px)/2);
    right: calc((100vw - 200px)/2);
    width: 200px;
    height: 100px;
    border: none;
    color: white;
    background-color: transparent;
    font-size: 4rem;
    font-family: 'Amatic SC';
    opacity: 0;
    transition: opacity 0.5s;
    display: none;
}

.start.fadeIn{
    opacity:1;
}

.start.fadeOut{
    opacity:0;
}

@media (hover: hover) and (pointer: fine) {
    .start:hover { color: #9400D3; }
  }


#cooking {
    position: relative;
    margin: auto ;
    top: calc((100vh - 55vmax)/2);
    width: 30vh;
    min-width: 30vmax;
    height: 30vh;
    min-height: 30vmax;
    overflow: visible;
    opacity: 1;
    transition: opacity 1s;
}

#cooking.fade {
    opacity: 0;
}

/* @media (orientation: landscape) {
    #cooking {
      top: 10vh;
    }
  }
  
  @media (orientation: portrait) {
    #cooking {
      top: 15vh;
    }
  } */

#cooking #area {
    position: absolute;
    bottom: 0;
    right: 2.5vw;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform-origin: 15% 60%;
    animation: flip 2.1s ease-in-out infinite;
}

#cooking #area #sides {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 15% 60%;
    animation: switchSide 2.1s ease-in-out infinite;
}

#cooking #area #sides #handle {
    position: absolute;
    bottom: 18%;
    right: 80%;
    width: 35%;
    height: 20%;
    background-color: transparent;
    border-top: 1vh solid #ffffff;
    border-left: 1vh solid transparent;
    border-radius: 100%;
    transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}
#cooking #area #sides #pan {
    position: absolute;
    bottom: 10%;
    right: 30%;
    width: 50%;
    height: 20%;
    background-color: #ffffff;
    border-radius: 0 0 1.4em 1.4em;
    transform-origin: -15% 0;
}

#cooking #area #pancake {
    position: absolute;
    top: 24%;
    width: 100%;
    height: 100%;
    transform: rotateX(85deg);
    animation: jump 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry {
    position: absolute;
    bottom: 26%;
    right: 40%;
    width: 20%;
    height: 30%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 #ffffff;
    border-radius: 100%;
    transform-origin: -140% 0;
    animation: fly 2.1s ease-in-out infinite;
}

/* #cooking #area #pancake #pastry {
    position: absolute;
    bottom: 26%;
    right: 37%;
    width: 40%;
    height: 45%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 #ffffff;
    border-radius: 100%;
    transform-origin: -20% 0;
    animation: fly 2.1s ease-in-out infinite;
} */

#cooking #area #pancake #pastry2 {
    position: absolute;
    bottom: 26%;
    right: 60%;
    width: 15%;
    height: 20%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 #ffffff;
    border-radius: 100%;
    transform-origin: -80% 0;
    animation: fly 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry3 {
    position: absolute;
    bottom: 26%;
    right: 70%;
    width: 10%;
    height: 25%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 #ffffff;
    border-radius: 100%;
    transform-origin: -160% 0;
    animation: fly 2.1s ease-in-out infinite;
}

#cooking .bubble {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0.25vh #ffffff;
    opacity: 0;
}

#cooking .bubble:nth-child(1) {
    margin-top: -9.5vh;
    left: 58%;
    width: 2.5vh;
    height: 2.5vh;
    background-color: #ffffff;
    animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#cooking .bubble:nth-child(2) {
    margin-top: -10vh;
    left: 52%;
    width: 2vh;
    height: 2vh;
    background-color: #ffffff;
    animation: bubble 2s ease-in-out 0.35s infinite; 
}

#cooking .bubble:nth-child(3) {
    margin-top: -8.8vh;
    left: 50%;
    width: 1.5vh;
    height: 1.5vh;
    background-color: #ffffff;
    animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(4) {
    margin-top: -9.7vh;
    left: 56%;
    width: 1.2vh;
    height: 1.2vh;
    background-color: #ffffff;
    animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(5) {
    margin-top: -9.7vh;
    left: 63%;
    width: 1.1vh;
    height: 1.1vh;
    background-color: #ffffff;
    animation: bubble 1.6s ease-in-out 1s infinite; 
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity: 0.25;
    }
    50% {
        transform: scale(1.2, 1);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0.25;
    }
}

@keyframes flip {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(-27deg);
    }

    30%, 50% {
        transform: rotate(0deg);
    }

    55% {
        transform: rotate(27deg);
    }

    83.3% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes switchSide {
    0% {
        transform: rotateY(0deg) ;
    }

    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes jump {
    0% {
        top: 24;
        transform: rotateX(85deg);
    }
    25% {
        top: 10%;
        transform: rotateX(0deg);
    }
    50% {
        top: 30%;
        transform: rotateX(85deg);
    }
    75% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(85deg);
    }
}

@keyframes fly {
    0% {
        bottom: 26%;
        transform: rotate(0deg);
    }
    10% {
        bottom: 40%;
    }
    50% {
        bottom: 26%;
        transform: rotate(-190deg);
    }
    80% {
        bottom: 40%;
    }
    100% {
        bottom: 26%;
        transform: rotate(0deg);
    }
}

@keyframes bubble {
    0% {
        transform: scale(0.15, 0.15);
        top: 80%;
        opacity: 0;
    }
    50% {
        transform: scale(1.1, 1.1);
        opacity: .4;
    }
    100% {
        transform: scale(0.33, 0.33);
        top: 60%;
        opacity: 0;
    }
}